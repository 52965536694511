import React from "react";
import "./App.css";
import "./chicken.css";
import "./neon.css";

function App() {
  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <img
          className="nugget"
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/77020/chicken-nugget.png"
        />
        <h1
          className="text-white text-2xl my-3 mb-5"
          style={{ fontSize: "xx-large" }}
        >
          <span
            className="cursor-pointer"
            onClick={() => {
              window.location.href = "https://nader.coffee";
            }}
          >
            Nader's
          </span>{" "}
          Chattanooga Fried Chicken Counter
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <h1 className="neon">6</h1>
      </div>
    </div>
  );
}

export default App;
